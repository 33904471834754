
  import { defineComponent, onMounted, ref } from 'vue';
  import { Button, Select } from '@voximplant/spaceui';
  import {
    $devices,
    setActiveDevices,
    setVideoQuality,
  } from '@/store/devices/index';
  import { requestMirrorStream, $mirrorStore } from '@/store/mirrorMedia/index';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'Video',
    components: {
      Button,
      Select,
    },
    setup() {
      const { t } = useI18n();
      const mirrorVideo = ref<HTMLVideoElement>();

      const updateVideoDevice = (e: VideoDeviceInfo) => {
        if (devices.value.videoEnabled) {
          requestMirrorStream({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedAudioDevice: devices.value.selectedAudioDevice,
            selectedVideoDevice: e,
          });
        } else {
          setActiveDevices({ selectedVideoDevice: e });
        }
      };
      const updateVideoQuality = (e: Record<string, string>) => {
        setVideoQuality(e);
      };

      onMounted(() => {
        $mirrorStore.watch((state) => {
          if (state.videoPreview && mirrorVideo.value) {
            mirrorVideo.value.srcObject = new MediaStream([state.videoPreview]);
            mirrorVideo.value?.play();
          }
        });
      });

      return {
        t,
        devices,
        mirrorVideo,
        updateVideoDevice,
        updateVideoQuality,
      };
    },
  });
