
  import { defineComponent } from 'vue';
  import ButtonStatus from '@/components/chat/ButtonStatus.vue';

  export default defineComponent({
    name: 'Message',
    components: {
      ButtonStatus,
    },
    props: {
      name: {
        type: String,
      },
      message: {
        type: String,
      },
      avatar: {
        type: String,
      },
      time: {
        type: String,
      },
      isPrivate: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
  });
