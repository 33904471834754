
  import { defineComponent, ref } from 'vue';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import { Button, Select } from '@voximplant/spaceui';
  import { useStore } from 'effector-vue/composition';
  import { authStore } from '@/store/auth';

  export default defineComponent({
    name: 'Profile',
    components: {
      SignInAccount,
      Button,
      Select,
    },
    props: {
      languagesList: {
        type: Array,
        default: () => [
          {
            label: 'Russian',
            value: 1,
          },
          {
            label: 'English',
            value: 2,
          },
          {
            label: 'Spanish',
            value: 3,
          },
        ],
      },
    },
    setup(props) {
      const activeItem = ref(props.languagesList[0]);
      const auth = useStore(authStore);

      return {
        activeItem,
        auth,
      };
    },
  });
