
  import { computed, defineComponent } from 'vue';
  import ContactItem from '@/components/chat/ContactItem.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { closeDrawer, openDrawer } from '@/store/drawer';
  import { Popup, Button, Input } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';
  import { getLink } from '@/helpers/link';
  import { $users } from '@/store/users';
  import { useI18n } from 'vue-i18n';
  import { toggleInvitePopup } from '@/store/popup';
  import InvitePeople from '@/components/InvitePeople.vue';
  import { useStore } from 'effector-vue/composition';

  export default defineComponent({
    name: 'ContactList',
    components: {
      InvitePeople,
      ContactItem,
      Button,
      Input,
      RadioInput,
      Popup,
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const link = getLink();
      const openPopup = () => toggleInvitePopup(true);
      const usersStore = useStore($users);
      const contacts = computed(() => {
        return [...usersStore.value.users, usersStore.value.me];
      });

      const inCall = computed(() => {
        return contacts.value.filter((user) => user?.inCall) || [];
      });

      const notActive = computed(() => {
        return contacts.value.filter((user) => !user?.inCall) || [];
      });
      return {
        t,
        link,
        openPopup,
        openDrawer,
        closeDrawer,
        isMobileScreen,
        inCall,
        notActive,
        contacts,
      };
    },
  });
