
  import { defineComponent, ref, computed, watch, Ref } from 'vue';
  import { Button, Input } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';
  import { sendMessageToAll } from '@/store/chat/index';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'TextArea',
    components: {
      Button,
      Input,
    },
    props: {
      typing: {
        type: Array,
        default: () => [],
      },
      iconColor: {
        type: String,
      },
      // isSelected - a initial state of the button or a user is selected
      isSelected: {
        type: Boolean,
        default: false,
      },
      users: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const { t } = useI18n();
      const message = ref('');
      const isActive = computed(() => Boolean(message.value.length));
      const { textArea } = useGrowInput(message);
      const { iconProps } = useIconProps(isActive);
      const { isMobileScreen } = useIsMobileScreen();
      const selectedUsers = props.users.join(', ');
      const userTyping = props.typing.join(', ');

      const sendMessage = () => {
        sendMessageToAll({ text: message.value });
        message.value = '';
      };

      const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.shiftKey && ev.key === 'Enter') {
          ev.preventDefault();
          ev.cancelBubble = true;
          sendMessage();
        }
      };

      return {
        t,
        textArea,
        message,
        isActive,
        iconProps,
        isMobileScreen,
        selectedUsers,
        userTyping,
        onKeyDown,
        sendMessage,
      };
    },
  });

  function useIconProps(isActive: Ref<boolean>) {
    const iconColor = computed(() => (isActive.value ? '--sui-purple-500' : '--sui-gray-600'));
    const iconProps = computed(() => ({
      spriteUrl: '/image/videoconf-icons.svg',
      name: `ic25-icon-send`,
      color: iconColor.value,
    }));

    return { iconProps };
  }

  function useGrowInput(message: Ref<string>) {
    const textArea = ref<HTMLTextAreaElement | null>(null);

    const autoGrow = (message: string) => {
      if (!textArea.value) return;

      if (message.length) {
        textArea.value.style.height = '25px';
        textArea.value.style.height = textArea.value.scrollHeight + 'px';
      } else {
        textArea.value.style.height = '25px';
      }
    };
    watch(message, autoGrow);

    return {
      textArea,
    };
  }
