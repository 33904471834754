
  import SpeakerName from '@/components/settings/SpeakerName.vue';
  import userVideoMock from '@/components/layouts/userVideoMock.vue';
  import usersOverflowMock from '@/components/layouts/usersOverflowMock.vue';
  import { Button } from '@voximplant/spaceui';
  import { defineComponent, ref, computed, watch, onBeforeUnmount, PropType } from 'vue';
  import { useMuteState } from '@/hooks/useMuteState';
  import { useConferenceEventState } from '@/hooks/useConferenceEventState';
  import MediaSlotReactions from '@/components/layouts/MediaSlotReactions.vue';
  import { useStore } from 'effector-vue/composition.cjs';
  import { overflowCheckpoint } from '@/helpers/layouts';
  import { $layout } from '@/store/layout';
  import { openDrawer } from '@/store/drawer';
  import { RenderVideoStore } from '@/store/endpointMedia/RenderVideoStore';
  import { $mediaSettings, $renderVideo, showOverflowUser } from '@/store/endpointMedia';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';

  const __default__ = defineComponent({
    name: 'VideoSlot',
    props: {
      tile: {
        type: Object as PropType<RenderVideoStore>,
        required: true,
      },
      index: {
        type: Number,
        required: true,
      },
      isShowName: {
        type: Boolean,
        defaults: true,
      },
      isOverLay: {
        type: Boolean,
        defaults: false,
      },
    },
    components: {
      MediaSlotReactions,
      userVideoMock,
      usersOverflowMock,
      SpeakerName,
      Button,
    },
    setup(props) {
      const { isMobileScreen } = useIsMobileScreen();
      const mediaSettings = useStore($mediaSettings);
      const stream = computed(() => props.tile.stream);
      const isEnabled = computed(
        () =>
          stream.value.id === 'local' ||
          Boolean(mediaSettings.value[stream.value.id]?.[stream.value.video.mid]?.active)
      );
      const reqVideoQuality = computed(
        () => 'req: ' + props.tile.width + ' x ' + props.tile.height
      );
      const gotVideoQuality = ref('No video');
      const getVideoQuality = () => {
        const video = stream.value.video?.mediaElement;
        if (video) {
          const videoTrack = (video.srcObject as MediaStream)?.getVideoTracks();
          const videoSettings = videoTrack[0].getSettings();
          gotVideoQuality.value = 'got: ' + videoSettings.width + ' x ' + videoSettings.height;
        } else {
          gotVideoQuality.value = 'got: ' + 'No video';
        }
      };
      let timerId: number;
      watch(
        () => props.isOverLay,
        (isOverLay) => {
          if (isOverLay) timerId = setInterval(() => getVideoQuality(), 200);
          else clearInterval(timerId);
        },
        { immediate: true }
      );
      onBeforeUnmount(() => clearInterval(timerId));
      let isShowNameProps = ref(props.isShowName);
      const userMedia = useStore($renderVideo);
      const layout = useStore($layout);
      const getOverflowCheckpoint = () => {
        const { type, kind } = layout.value;

        return overflowCheckpoint[type][kind];
      };
      const isOverFlow = computed(() => props.index >= getOverflowCheckpoint());
      const onClickMediaSlot = () => {
        if (!isMobileScreen) return;
        isShowNameProps.value = !isShowNameProps.value;
      };
      const containerElement = ref<HTMLDivElement | null>(null);
      const videoSlot = ref<HTMLDivElement | null>(null);

      const uppUser = () => {
        showOverflowUser(props.index);
      };

      const clickOverflow = () => {
        if (isMobileScreen) openDrawer('contactList');
        else uppUser();
      };

      const appendVideo = () => {
        if (!videoSlot.value) return;
        const mediaElement = stream.value.video?.mediaElement;
        if (containerElement.value && mediaElement) {
          mediaElement.setAttribute('id', stream.value.id);
          videoSlot.value.appendChild(mediaElement);
          if (mediaElement.paused) {
            mediaElement.play().catch((e) => console.error('VideoSlot: playback error', e));
          }
        }
      };
      watch(videoSlot, appendVideo);

      const muted = useMuteState(stream.value.id);
      const isVoiceActive = useConferenceEventState('vad', stream.value.id);
      const videoSlotClasses = computed(() => ({
        'voice-active': !isOverFlow.value && isVoiceActive.value,
      }));

      const isContain = computed(
        () => stream.value.id.includes('local') || stream.value.kind.includes('screen')
      );
      const isScaleX = computed(
        () => stream.value.id.includes('local') && !stream.value.kind.includes('screen')
      );
      const videoStyles = computed(() => ({
        objectFit: isContain.value ? 'contain' : 'cover',
        transform: isScaleX.value ? 'scaleX(-1)' : 'none',
      }));

      return {
        stream,
        reqVideoQuality,
        gotVideoQuality,
        clickOverflow,
        containerElement,
        videoSlot,
        muted,
        isVoiceActive,
        videoSlotClasses,
        videoStyles,
        isShowNameProps,
        onClickMediaSlot,
        userMedia,
        isOverFlow,
        getOverflowCheckpoint,
        isEnabled,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "f7f31102": (_ctx.videoStyles.objectFit),
  "1b8eae99": (_ctx.videoStyles.transform)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__