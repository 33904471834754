
  import { defineComponent, ref } from 'vue';
  import SpeakerName from '@/components/settings/SpeakerName.vue';
  import userVideoMock from '@/components/layouts/userVideoMock.vue';
  import VideoSlot from '@/components/media/VideoSlot.vue';
  import { Button } from '@voximplant/spaceui';
  import { RawOutput } from '@/store/webrtc/endpoints';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';

  // TODO контейнер для видео
  export default defineComponent({
    name: 'LayoutsVideo',
    props: {
      videos: {
        type: Array,
        default: () => [],
      },
    },
    components: {
      userVideoMock,
      SpeakerName,
      VideoSlot,
      Button,
    },
    setup() {
      const isOverLay = ref(false);
      const onKeyDown = (ev: KeyboardEvent) => {
        if (ev.altKey && ev.shiftKey) {
          switch (ev.code) {
            case 'KeyS':
              isOverLay.value = !isOverLay.value;
              break;
          }
          ev.preventDefault();
          ev.cancelBubble = true;
        }
      };
      document.addEventListener('keydown', onKeyDown);
      const { isMobileScreen } = useIsMobileScreen();
      const isShowMediaElement = (videos: RawOutput[]) => {
        return videos.length ? videos[0].height > 0 : false;
      };
      return {
        isShowMediaElement,
        isMobileScreen,
        isOverLay,
      };
    },
  });
