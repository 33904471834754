
  import { defineComponent } from 'vue';
  import TextArea from '@/components/chat/TextArea.vue';
  import { openDrawer } from '@/store/drawer';
  import { $chatContent } from '@/store/chat/index';
  import { Button } from '@voximplant/spaceui';
  import Message from '@/components/chat/Message.vue';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'ChatArea',
    components: {
      Message,
      TextArea,
      Button,
    },
    props: {
      chatList: {
        type: Array,
        default: () => [],
      },
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      return {
        openDrawer,
        chatContent: $chatContent,
        isMobileScreen,
        t,
      };
    },
  });
