
  import { defineComponent } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import { $sharing } from '@/store/webrtc/endpoints';
  import { endpointEventList } from '@/store/webrtc/endpointManager';
  import { SharingType } from '@/store/sharing';
  import { useStore } from 'effector-vue/composition.cjs';
  import { Vendor, userAgent } from '@/helpers/vendor';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'SharingButton',
    components: {
      PopUpWithButton,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const listSharing = [
        {
          icon: 'ic25-sharing',
          title: t('buttons.sharingButtons.withVideo'),
          type: 'withVideo',
        },
        {
          icon: 'ic25-screen',
          title: t('buttons.sharingButtons.replace'),
          type: 'replace',
        },
      ];
      const sharingState = useStore($sharing);
      const sharingButtons =
        userAgent === Vendor?.chrome
          ? [
              {
                icon: 'ic25-sharing',
                title: t('buttons.sharingButtons.withVideoAndAudio'),
                type: 'withVideoAndAudio',
              },
              ...listSharing,
            ]
          : listSharing;
      const toggleScreenSharing = (type: SharingType) => {
        if (!$sharing.getState().isSharing) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          endpointEventList.addSharing(type);
        } else {
          endpointEventList.removeSharing();
        }
      };

      return {
        toggleScreenSharing,
        sharingState,
        sharingButtons,
      };
    },
  });
