
  import { computed, defineComponent, PropType, ref } from 'vue';
  import { Icon, Button } from '@voximplant/spaceui';
  import { useIsMobileScreen } from '@/hooks/useIsMobile';
  import MeetingInfoWithPopup from '@/components/settings/MeetingInfoWithPopup.vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import SettingsButton from '@/components/panel-buttons/SettingsButton.vue';
  import UsersButton from '@/components/panel-buttons/UsersButton.vue';
  import MenuButton from '@/components/panel-buttons/MenuButton.vue';
  import ReactionButton from '@/components/panel-buttons/ReactionButton.vue';
  import VideoButton from '@/components/panel-buttons/VideoButton.vue';
  import AudioButton from '@/components/panel-buttons/AudioButton.vue';
  import SharingButton from '@/components/panel-buttons/SharingButton.vue';
  import Tooltip from '@/components/info/Tooltip.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { useRoute, useRouter } from 'vue-router';
  import { $drawer, closeDrawer, DrawerSection, openDrawer } from '@/store/drawer';
  import { useStore } from 'effector-vue/composition';
  import { endpointEventList } from '@/store/webrtc/endpointManager';
  import { $devices, toggleAudioEvent, toggleVideoEvent } from '@/store/devices/index';
  import { toggleMirrorAudioStream, toggleMirrorVideoStream } from '@/store/mirrorMedia/index';
  import { canScreen } from '@/helpers/vendor';
  import { useI18n } from 'vue-i18n';
  import { $sharing } from '@/store/sharing';

  interface PanelMode {
    default?: string;
    admin?: string;
  }

  const modes: PanelMode = {
    default: 'default',
    admin: 'admin',
  };

  export default defineComponent({
    name: 'CallButtonPanel',
    components: {
      Tooltip,
      SharingButton,
      AudioButton,
      VideoButton,
      ReactionButton,
      MenuButton,
      UsersButton,
      SettingsButton,
      PopUpWithButton,
      Icon,
      Button,
      MeetingInfoWithPopup,
      RadioInput,
    },
    // mode = admin - admin settings
    // mode = default - default settings
    props: {
      mode: {
        type: String as PropType<keyof PanelMode>,
        default: 'admin',
      },
      link: {
        type: String,
        default: '/image/videoconf-icons.svg',
        required: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const { isMobileScreen } = useIsMobileScreen();
      const { path, params } = useRoute();
      const router = useRouter();
      const devices = useStore($devices);
      const button = ref<HTMLElement | null>(null);
      const drawerState = useStore($drawer);
      const toggleAudio = () => {
        toggleAudioEvent();
        toggleMirrorAudioStream();
      };
      const toggleVideo = () => {
        toggleVideoEvent();
        toggleMirrorVideoStream();
      };
      const sharingState = useStore($sharing);
      const sharingTooltip = computed(() =>
        sharingState.value.isSharing ? t('buttons.stopScreenShare') : t('buttons.screenShare')
      );

      const iconCamName = computed(() =>
        devices.value.videoEnabled ? 'ic25-video-on' : 'ic25-video-off'
      );

      const iconMicName = computed(() =>
        devices.value.audioEnabled ? 'ic25-mic-on' : 'ic25-mic-off'
      );

      const contactListIconColor = computed(() =>
        drawerState.value.opened && drawerState.value.section === 'contactList'
          ? '--sui-purple-500'
          : '--sui-gray-700'
      );
      const chatIconColor = computed(() =>
        drawerState.value.opened && drawerState.value.section === 'chat'
          ? '--sui-purple-500'
          : '--sui-gray-700'
      );

      const handleOpenDrawer = (section: DrawerSection) => {
        if (drawerState.value.opened && drawerState.value.section === section) {
          return closeDrawer();
        }

        openDrawer(section);
      };

      const leaveConference = () => {
        endpointEventList.clearAll();
        router.replace({
          name: 'Feedback',
        });
      };

      return {
        t,
        devices,
        isMobileScreen,
        path,
        params,
        modes,
        contactListIconColor,
        chatIconColor,
        button,
        handleOpenDrawer,
        leaveConference,
        iconMicName,
        iconCamName,
        toggleAudio,
        toggleVideo,
        sharingTooltip,
        canScreen,
      };
    },
  });
