
  import { defineComponent } from 'vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import EmojiPanel from '@/components/chat/EmojiPanel.vue';

  export default defineComponent({
    name: 'ReactionButton',
    components: {
      EmojiPanel,
      PopUpWithButton,
    },
  });
