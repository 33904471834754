
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { useI18n } from 'vue-i18n';
  import { $convertedMedia } from '@/store/endpointMedia';

  export default defineComponent({
    name: 'usersOverflowMock',
    props: {
      users: {
        type: Array,
      },
      index: {
        type: Number,
        default: 0,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const mockName = (name: string) => {
        const surnameIndex = name.indexOf(' ') + 1;
        const firstSurnameLetter = surnameIndex ? name.substr(surnameIndex, 1) : '';
        return name.substr(0, 1) + firstSurnameLetter;
      };
      const endpointsMedia = useStore($convertedMedia);
      const lastUsers = computed(() => {
        if (!endpointsMedia?.value.length) return [];
        return endpointsMedia?.value?.slice(props.index, props.index + 3);
      });

      const blockClasses = computed(() => [
        { twoOther: lastUsers.value.length === 2 },
        { threeOther: lastUsers.value.length === 3 },
      ]);

      return {
        t,
        mockName,
        lastUsers,
        endpointsMedia,
        blockClasses,
      };
    },
  });
