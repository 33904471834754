
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithChevron from '@/components/popups/PopUpWithChevron.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { Button } from '@voximplant/spaceui';
  import { openModal } from '@/store/modal';
  import { useStore } from 'effector-vue/composition';
  import {
    $devices,
    setActiveDevices,
    toggleVideoEvent,
  } from '@/store/devices/index';
  import { requestMirrorStream, toggleMirrorVideoStream } from '@/store/mirrorMedia/index';
  import { useI18n } from 'vue-i18n';
  import { VideoDeviceInfo } from '@/store/devices/VideoDeviceInfo';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';
  const devices = useStore($devices);

  export default defineComponent({
    name: 'VideoButton',
    components: {
      RadioInput,
      PopUpWithChevron,
      TitleOption,
      Button,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { t } = useI18n();
      const iconCamName = computed(() =>
        devices.value.videoEnabled ? 'ic25-video-on' : 'ic25-video-off'
      );
      const camTooltip = computed(() =>
        devices.value.videoEnabled ? t('buttons.cameraOff') : t('buttons.cameraOn')
      );
      const listCamera = computed(() => devices.value.videoDevices || []);
      const muted = ref(false);
      const toggleVideo = () => {
        muted.value = !muted.value;
        toggleVideoEvent();
        toggleMirrorVideoStream();
      };
      const updateVideoDevice = (e: VideoDeviceInfo) => {
        if (devices.value.videoEnabled) {
          requestMirrorStream({
            selectedAudioDevice: $devices.getState().selectedAudioDevice,
            selectedVideoDevice: e,
          });
        } else {
          setActiveDevices({ selectedVideoDevice: e });
        }
      };
      const showVideoSettings = () => {
        openModal({ opened: true, componentOptions: { initTab: 'Video' } });
      };
      const isActive = (item: AudioDeviceInfo | never) => {
        return item.value === $devices.getState().selectedVideoDevice?.value;
      };

      onMounted(() => {
        if (!$devices.getState().videoEnabled) {
          muted.value = !muted.value;
        }
      });

      return {
        t,
        iconCamName,
        camTooltip,
        listCamera,
        toggleVideo,
        updateVideoDevice,
        showVideoSettings,
        isActive,
      };
    },
  });
