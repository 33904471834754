
  import { defineComponent, ref } from 'vue';
  import { Button } from '@voximplant/spaceui';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithButton from '@/components/popups/PopUpWithButton.vue';
  import MenuSettingsItem from '@/components/inputs/MenuSettingsItem.vue';
  import { openModal } from '@/store/modal';
  import Modal from '@/components/Modal.vue';
  import GeneralSettingsMenu from '@/components/settings/GeneralSettingsMenu.vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition.cjs';
  import { $drawer, closeDrawer, openDrawer } from '@/store/drawer';

  export default defineComponent({
    name: 'MenuButton',
    components: {
      GeneralSettingsMenu,
      Modal,
      MenuSettingsItem,
      PopUpWithButton,
      TitleOption,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const isGeneralSettings = ref(false);
      const drawerStore = useStore($drawer);
      const listMenuDefault = [
        /* {
          icon: 'ic25-jamboard',
          title: 'Jamboard',
        },
        {
          icon: 'ic25-background',
          title: 'Background and stickers',
        },
        {
          icon: 'ic25-layouts',
          title: 'Full screen',
        },
        {
          icon: 'ic25-record',
          title: 'Record Screen',
        },
        {
          icon: 'ic25-captioning',
          title: 'Captions',
        }, */
        {
          icon: 'ic25-layouts',
          title: t('menuList.changeLayout'),
          click: () => {
            if (!drawerStore.value.opened) {
              openDrawer('changeLayout');
            } else {
              closeDrawer();
            }
          },
        },
      ];
      return {
        showModal: () => {
          openModal({
            opened: true,
            componentOptions: {
              initTab: 'Profile',
            },
          });
        },
        t,
        listMenuDefault,
        isGeneralSettings,
      };
    },
  });
