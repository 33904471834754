
  import { computed, defineComponent, ref } from 'vue';
  import { Button, Icon } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'effector-vue/composition.cjs';
  import { useMuteState } from '@/hooks/useMuteState';
  import { $endpoints } from '@/store/webrtc/endpoints';
  import { EndpointDescriptionStore } from '@/store/webrtc/endpointTypes';
  import { reactionsStore, updateReaction } from '@/store/reactions';

  export default defineComponent({
    name: 'ContactItem',
    components: {
      Button,
      Icon,
    },
    props: {
      item: {
        type: String,
      },
      userName: {
        type: String,
        required: true,
      },
      endpointId: {
        type: String,
        required: true,
      },
      image: {
        type: String,
      },
      isContact: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        required: true,
      },
    },
    setup(props) {
      const { t } = useI18n();
      const endpoints = useStore($endpoints);
      const itemClasses = computed(() =>
        props.type === 'offline' ? 'item-without-button' : 'item-with-button'
      );
      const emojiClass = computed(() => (userReactions.value?.size ? '' : 'mock'));
      const reactions = useStore(reactionsStore);
      const userReactions = computed(() => reactions.value.reactions[props.userName]);
      const lastEmoji = ref(reactions.value.emojilist['hand']);
      const muted = ref(useMuteState(props.endpointId));
      const userMedia = computed(
        () =>
          endpoints.value.endpoints.find(
            (media: EndpointDescriptionStore) => media.id === props.endpointId
          )?.mid
      );
      const videoEnabled = computed(
        () => userMedia.value && Object.values(userMedia.value).includes('video')
      );
      const iconMicName = computed(() =>
        muted.value ? 'ic25-contact-mic-off' : 'ic25-contact-mic-on'
      );
      const iconCamName = computed(() =>
        videoEnabled.value ? 'ic25-contact-video-on' : 'ic25-contact-video-off'
      );
      userReactions.value?.forEach((emoji) => {
        lastEmoji.value = reactions.value.emojilist[emoji];
      });
      updateReaction.watch((reaction) => {
        if (reaction.userName === props.userName) {
          if (reaction.active) {
            lastEmoji.value = reactions.value.emojilist[reaction.type];
          } else if (!reaction.active && userReactions.value?.size !== 0) {
            userReactions.value?.forEach((emoji) => {
              lastEmoji.value = reactions.value.emojilist[emoji];
            });
          } else {
            lastEmoji.value = reactions.value.emojilist['hand'];
          }
        }
      });

      return {
        t,
        iconMicName,
        iconCamName,
        itemClasses,
        lastEmoji,
        emojiClass,
      };
    },
  });
