
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import Tooltip from '@/components/info/Tooltip.vue';
  import TitleOption from '@/components/settings/TitleOption.vue';
  import PopUpWithChevron from '@/components/popups/PopUpWithChevron.vue';
  import RadioInput from '@/components/inputs/RadioInput.vue';
  import { Button } from '@voximplant/spaceui';
  import { openModal } from '@/store/modal';
  import { useStore } from 'effector-vue/composition.cjs';
  import {
    $devices,
    selectSpeakerDevice,
    toggleAudioEvent,
  } from '@/store/devices';
  import { requestMirrorStream, toggleMirrorAudioStream } from '@/store/mirrorMedia/index';
  import { localMute } from '@/store/conferenceEvents';
  import { useI18n } from 'vue-i18n';
  import { userAgent, Vendor } from '@/helpers/vendor';
  import { AudioDeviceInfo } from '@/store/devices/AudioDeviceInfo';

  export default defineComponent({
    name: 'AudioButton',
    components: {
      Tooltip,
      RadioInput,
      PopUpWithChevron,
      TitleOption,
      Button,
    },
    setup() {
      const { t } = useI18n();
      const devices = useStore($devices);
      const iconMicName = computed(() =>
        devices.value.audioEnabled ? 'ic25-mic-on' : 'ic25-mic-off'
      );
      const micTooltip = computed(() =>
        devices.value.audioEnabled ? t('buttons.audioMute') : t('buttons.audioUnmute')
      );
      const listMicrophone = computed(() => devices.value.audioDevices || []);
      const listSpeaker = computed(() => devices.value.speakerDevices || []);
      const muted = ref(false);
      const toggleAudio = () => {
        muted.value = !muted.value;
        toggleAudioEvent();
        toggleMirrorAudioStream();
        localMute(muted.value);
      };
      const canUseOutputDevice = () => {
        return userAgent === Vendor.chrome;
      };

      const updateAudioDevice = (e: AudioDeviceInfo) => {
        if (e.kind === 'audioinput') {
          requestMirrorStream({
            selectedAudioDevice: e,
          });
        } else if (e.kind === 'audiooutput') {
          selectSpeakerDevice(e);
        }
      };
      const showAudioSettings = () => {
        openModal({ opened: true, componentOptions: { initTab: 'Audio' } });
      };
      const isAudioActive = (item: AudioDeviceInfo) => {
        const { selectedAudioDevice } = $devices.getState();
        return (
          item.groupId === selectedAudioDevice?.groupId && item.label === selectedAudioDevice?.label
        );
      };

      const isSpeakerActive = (item: AudioDeviceInfo) => {
        return (
          item.groupId === devices.value.selectedSpeakerDevices?.groupId &&
          item.label === devices.value.selectedSpeakerDevices.label
        );
      };

      onMounted(() => {
        if (!devices.value.audioEnabled) {
          muted.value = !muted.value;
          toggleMirrorAudioStream();
          localMute(muted.value);
        }
      });

      return {
        t,
        listMicrophone,
        listSpeaker,
        iconMicName,
        micTooltip,
        updateAudioDevice,
        showAudioSettings,
        isAudioActive,
        isSpeakerActive,
        toggleAudio,
        canUseOutputDevice,
      };
    },
  });
